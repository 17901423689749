import axios from "../axios";
import { configSchema } from "../interfaces/config";

export async function getConfig() {
  try {
    const response = await axios.get("/config");
    return configSchema.parse(response.data);
  } catch (error) {
    console.error("Error fetching config:", error);
    throw new Error("Failed to fetch config");
  }
}
