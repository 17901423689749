import { useMutation } from "@tanstack/react-query";
import { type Config, signMessage } from "@wagmi/core";
import { getLoginNonce, login, logout } from "@/data/api/auth";
import type { Address } from "viem";
import { createSiweMessage } from "viem/siwe";
import toast from "react-hot-toast";
import { SiweStatus } from "./useSiwe";

const generateSiweMessage = async (
  address: Address,
  chainId: number,
  nonce: string,
) => {
  return createSiweMessage({
    domain: window.location.host,
    address,
    statement: "Sign in with Ethereum to the app.",
    uri: window.location.origin,
    version: "1",
    chainId,
    nonce,
  });
};

async function requestSiweLogin(
  address: Address,
  chainId: number | undefined,
  rpcConfig: Config,
) {
  try {
    if (!chainId) {
      throw new Error("Chain ID is not provided");
    }
    await logout();
    const nonce = await getLoginNonce();
    const siweMessage = await generateSiweMessage(address, chainId, nonce);
    const signature = await signMessage(rpcConfig, {
      message: siweMessage,
      account: address,
    });

    await login(siweMessage, signature);
  } catch (error) {
    console.error("Error in SIWE login:", error);
    throw new Error("Failed to login with SIWE");
  }
}

export const useSiweMutation = (
  setSiweStatus: React.Dispatch<React.SetStateAction<SiweStatus>>,
  rpcConfig: Config,
) =>
  useMutation({
    mutationFn: ({
      address,
      chainId,
    }: {
      address: Address;
      chainId: number | undefined;
    }) => requestSiweLogin(address, chainId, rpcConfig),
    onMutate: () => setSiweStatus(SiweStatus.Loading),
    onSuccess: () => setSiweStatus(SiweStatus.Authenticated),
    onError: () => {
      toast.error("Login failed, please try again.");
      setSiweStatus(SiweStatus.Unauthenticated);
    },
  });
