import { getConfig } from "@/data/api/config";
import { useQuery } from "@tanstack/react-query";

export const useConfig = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["config"],
    queryFn: async () => {
      const response = await getConfig();
      return response;
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  return { config: data, isLoading, isError };
};
