export const URL_PATHS = {
  REPORT: "/dashboard/report",
  TRANSACTIONS: "/dashboard/transactions",
  SUBSCRIBE: "/subscribe",
  REDEEM: "/redeem",
  NOT_FOUND: "/404",
};

export const PROTECTED_PATHS = [
  URL_PATHS.TRANSACTIONS,
  URL_PATHS.SUBSCRIBE,
  URL_PATHS.REDEEM,
];
