"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import { watchAccount } from "@wagmi/core";
import { getIsAuthenticated, logout } from "@/data/api/auth";
import { useAccountEffect } from "wagmi";
import { useSiweMutation } from "./useSiweMutation";
import { useConfigContext } from "@/context/configContext";

export enum SiweStatus {
  Initializing = "initializing",
  Authenticated = "authenticated",
  Unauthenticated = "unauthenticated",
  Loading = "loading",
}

export const useSiwe = () => {
  const { chainConfig } = useConfigContext();

  const [siweStatus, setSiweStatus] = useState<SiweStatus>(
    SiweStatus.Initializing,
  );
  const isFirstRun = useRef(true);

  const { mutate: siwe } = useSiweMutation(setSiweStatus, chainConfig);

  const checkAuthentication = useCallback(async () => {
    try {
      await getIsAuthenticated();
      setSiweStatus(SiweStatus.Authenticated);
    } catch {
      setSiweStatus(SiweStatus.Unauthenticated);
    }
  }, []);

  useAccountEffect({
    async onDisconnect() {
      await logout();
      setSiweStatus(SiweStatus.Unauthenticated);
    },
  });

  useEffect(() => {
    if (isFirstRun.current) {
      checkAuthentication();
      isFirstRun.current = false;
      return;
    }

    const unwatch = watchAccount(chainConfig, {
      onChange(account, prevAccount) {
        if (
          account.status === "connected" &&
          account.address &&
          account.address !== prevAccount.address &&
          siweStatus !== SiweStatus.Loading
        ) {
          siwe({ address: account.address, chainId: account.chainId });
        }
      },
    });

    return () => unwatch();
  }, [siwe, checkAuthentication, siweStatus, chainConfig]);

  return { siweStatus, siwe };
};
