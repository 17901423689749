import { type Address, isAddress } from "viem";
import { z } from "zod";

const rpcDataSchema = z.object({
  rpc: z.string(),
  id: z
    .number()
    .refine((id) => id > 0, { message: "Invalid chain ID" })
    .optional(),
});

const rpcSchema = z.record(z.string(), rpcDataSchema);

const addressSchema = z
  .string()
  .refine((value) => isAddress(value), {
    message: "Invalid address format.",
  })
  .transform((value) => value as Address);

const sharesSchema = z.object({
  chain: z.string(),
  address: addressSchema,
});

const safeSchema = z.object({
  chain: z.string(),
  address: addressSchema,
  nav: addressSchema,
});

const fundSchema = z.object({
  denominationAsset: addressSchema,
  name: z.string(),
  shares: sharesSchema,
  safes: z.array(safeSchema),
});

export const configSchema = z.object({
  funds: z.array(fundSchema),
  rpc: rpcSchema,
});

type SharesConfig = z.infer<typeof sharesSchema>;

type SafesConfig = z.infer<typeof safeSchema>;

type FundConfig = z.infer<typeof fundSchema>;

type FundsConfig = z.infer<typeof configSchema>["funds"];

type RpcConfig = z.infer<typeof rpcSchema>;

type Config = z.infer<typeof configSchema>;

type RpcData = z.infer<typeof rpcDataSchema>;

export type SupportedChain = z.infer<typeof sharesSchema>["chain"];

export type {
  RpcData,
  RpcConfig,
  Config,
  SharesConfig,
  SafesConfig,
  FundConfig,
  FundsConfig,
};
