import axios from "../axios";

interface NonceResponse {
  nonce: string;
}

export async function getLoginNonce(): Promise<string> {
  try {
    const response = await axios.get<NonceResponse>("/login/nonce");
    return response.data.nonce;
  } catch (error) {
    console.error("Error fetching login nonce:", error);
    throw new Error("Failed to fetch login nonce");
  }
}

export async function login(message: string, signature: string) {
  try {
    await axios.post("/login", {
      message,
      signature,
    });
  } catch (error) {
    console.error("Error while trying to login:", error);
    throw new Error("Failed to login");
  }
}

export async function logout() {
  try {
    await axios.post("/logout");
  } catch (error) {
    console.error("Error loggin out:", error);
    throw new Error("User logout failed");
  }
}

export async function getIsAuthenticated() {
  try {
    await axios.get("/profile");
  } catch (error) {
    console.error("Error loggin out:", error);
    throw new Error("User not authenticated");
  }
}
