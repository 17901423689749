"use client";

import useAuthorization from "@/hooks/auth/useAuthorization";
import type { SiweStatus } from "@/hooks/auth/useSiwe";
import type { UseMutateFunction } from "@tanstack/react-query";
import { createContext, useContext, type ReactNode } from "react";
import type { Address } from "viem";

type AuthorizationContextType = {
  isLoggedIn: boolean;
  isOnCorrectNetwork: boolean;
  isAuthorizationReady: boolean;
  isUserInvestor: boolean;
  isConnected: boolean;
  address: Address;
  chain: number | undefined;
  siweStatus: SiweStatus;
  isAccessDeniedModalOpen: boolean;
  siwe: () => void;
  showAccessDeniedModal: () => void;
  closeAccessDeniedModal: () => void;
};

const AuthorizationContext = createContext<
  AuthorizationContextType | undefined
>(undefined);

export const AuthorizationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    isLoggedIn,
    isOnCorrectNetwork,
    isAuthorizationReady,
    isUserInvestor,
    isConnected,
    isAccessDeniedModalOpen,
    address,
    chain,
    siweStatus,
    siwe,
    showAccessDeniedModal,
    closeAccessDeniedModal,
  } = useAuthorization();

  return (
    <AuthorizationContext.Provider
      value={{
        isLoggedIn,
        isOnCorrectNetwork,
        isAuthorizationReady,
        isUserInvestor,
        isConnected,
        address,
        chain,
        siweStatus,
        isAccessDeniedModalOpen,
        siwe,
        showAccessDeniedModal,
        closeAccessDeniedModal,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export const useAuthorizationContext = () => {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error(
      "useAuthorizationContext must be used within an AuthorizationProvider",
    );
  }
  return context;
};
