import classNames from "classnames";
import type { SVGProps } from "react";

interface InteractiveDotProps extends SVGProps<SVGSVGElement> {
  active: boolean;
  className?: string;
}

const InteractiveCircle = ({
  active,
  className,
  ...props
}: InteractiveDotProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("flex-shrink-0", className)}
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={9}
      className={classNames({
        "fill-gray-900 stroke-none": active,
        "fill-none stroke-gray-400 group-hover:stroke-gray-900": !active,
      })}
      strokeWidth={2}
    />
  </svg>
);
export default InteractiveCircle;
