"use client";

import { useState, useEffect } from "react";
import Link from "next/link";
import classNames from "classnames";
import InteractiveDot from "./Icons/InteractiveCircle";
import { usePathname } from "next/navigation";
import { URL_PATHS } from "@/constants/navigation";
import { useAuthorizationContext } from "@/context/authorizationContext";
import { debounce } from "lodash";

interface TabProps {
  children: React.ReactNode;
  currentPath: string;
  tabPath: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

interface SubtabProps {
  children: React.ReactNode;
  activeSection: string;
  sectionId: string;
  disabled?: boolean;
}

const REPORT_BASE_PATH = URL_PATHS.REPORT;

const sectionIds = [
  "fund-properties",
  "fund-performance",
  "portfolio",
  "user-position",
];
const options = { 
  root: null, 
  rootMargin: "-20% 0px -30% 0px",
  threshold: [0, 0.25, 0.5, 0.75, 1] 
};

export default function Sidebar() {
  const pathname = usePathname();
  const { isLoggedIn, showAccessDeniedModal, isAuthorizationReady } =
    useAuthorizationContext();
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    if (pathname === REPORT_BASE_PATH) {
      let currentSection = '';
      
      const isNearBottom = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const totalHeight = document.documentElement.scrollHeight;
        const threshold = totalHeight - (window.innerHeight * 0.1); // Check when within 30% of bottom
        return scrollPosition >= threshold;
      };

      const debouncedSetActiveSection = debounce((newSection: string) => {
        if (newSection !== currentSection) {
          currentSection = newSection;
          setActiveSection(newSection);
        }
      }, 100);

      const observer = new IntersectionObserver((entries) => {
        if (isNearBottom()) {
          debouncedSetActiveSection('user-position');
          return;
        }

        const visibleSections = entries
          .filter(entry => entry.isIntersecting)
          .sort((a, b) => b.intersectionRatio - a.intersectionRatio);

        if (visibleSections.length > 0) {
          const mostVisibleSection = visibleSections[0].target.id;
          debouncedSetActiveSection(mostVisibleSection);
        }
      }, options);

      const handleScroll = () => {
        if (isNearBottom()) {
          debouncedSetActiveSection('user-position');
        }
      };

      window.addEventListener('scroll', handleScroll, { passive: true });

      for (const id of sectionIds) {
        const section = document.getElementById(id);
        if (section) observer.observe(section);
      }

      if (isNearBottom()) {
        debouncedSetActiveSection('user-position');
      }

      return () => {
        observer.disconnect();
        debouncedSetActiveSection.cancel();
        window.removeEventListener('scroll', handleScroll);
        setActiveSection("");
      };
    }
  }, [pathname]);

  const handleTransactionsClick = (e: React.MouseEvent) => {
    if (!isLoggedIn) {
      e.preventDefault();
      showAccessDeniedModal();
    }
    setActiveSection("");
  };

  return (
    <nav className="fixed w-64 h-full border-r border-gray-400 z-30 mt-28 py-5 pl-10 pr-5">
      <Tab currentPath={pathname} tabPath={URL_PATHS.REPORT}>
        Report
      </Tab>

      <div className="ml-4 mt-[-14px]">
        <Subtab activeSection={activeSection} sectionId="fund-properties">
          Fund properties
        </Subtab>
        <Subtab activeSection={activeSection} sectionId="fund-performance">
          Fund performance
        </Subtab>
        <Subtab activeSection={activeSection} sectionId="portfolio">
          Portfolio
        </Subtab>
        {isLoggedIn && (
          <Subtab activeSection={activeSection} sectionId="user-position">
            Your position
          </Subtab>
        )}
      </div>

      <Tab
        currentPath={pathname}
        tabPath={URL_PATHS.TRANSACTIONS}
        onClick={handleTransactionsClick}
        disabled={!isAuthorizationReady}
      >
        Transactions
      </Tab>
    </nav>
  );
}

function Tab({
  currentPath,
  tabPath,
  children,
  onClick,
  disabled = false,
}: TabProps) {
  const active = currentPath === tabPath;

  return (
    <Link
      href={tabPath}
      onClick={(e) => (disabled ? e.preventDefault() : onClick?.(e))}
    >
      <div className="py-5 flex flex-row items-center gap-2 group">
        <InteractiveDot active={active} />
        <span
          className={classNames(
            "font-mono font-bold text-xl",
            active
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {children}
        </span>
      </div>
    </Link>
  );
}

function Subtab({ activeSection, sectionId, children }: SubtabProps) {
  const active = activeSection === sectionId;
  const fullPath = `${REPORT_BASE_PATH}#${sectionId}`;
  return (
    <Link href={fullPath} scroll={true}>
      <div className="py-2 flex flex-row items-center gap-1 group">
        <InteractiveDot active={active} />
        <span
          className={classNames(
            "font-mono font-bold text-xl pl-2",
            active
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {children}
        </span>
      </div>
    </Link>
  );
}
