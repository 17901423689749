"use client";

import { createContext, useContext, type ReactNode, useMemo } from "react";
import { useConfig } from "@/hooks/config/useConfig";
import type {
  FundConfig,
  RpcConfig,
  Config as AppConfig,
} from "@/data/interfaces/config";
import { config as getChainConfig } from "@/wagmi";
import type { Config } from "@wagmi/core";

type ConfigContextType = {
  fund: FundConfig;
  chainConfig: Config;
  isLoading: boolean;
  isError: boolean;
};

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

const getFundChains = (config: AppConfig): RpcConfig => {
  const { funds, rpc } = config;
  const usedChains = funds.map((fund) => fund.shares.chain);

  return Object.fromEntries(
    Object.entries(rpc).filter(([chain]) => usedChains.includes(chain)),
  );
};

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const { config, isLoading, isError } = useConfig();

  const chainConfig = useMemo(() => {
    return config?.rpc ? getChainConfig(getFundChains(config)) : undefined;
  }, [config]);

  if (isLoading) {
    return <div />;
  }

  if (isError || config === undefined || chainConfig === undefined) {
    return <div>Error loading config. Please try again later.</div>;
  }

  return (
    <ConfigContext.Provider
      value={{ fund: config.funds[0], chainConfig, isLoading, isError }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
